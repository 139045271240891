<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.xsOnly}">
    <v-row dense>
      <BidStream></BidStream>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="py-0">
        <profile-home
          :key="`${playerProfileId}-${mId}-${user ? user.id : 0}`"
          :playerProfileId="playerProfileId"
          :playerName="name"
          :profilePic="profilePic"
          :memberId="mId"
        ></profile-home>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProfileHome from '@/components/Player/ProfileHome'
import { mapGetters } from 'vuex'

export default {
  props: ['playerProfileId', 'sbid', 'name', 'profilePic', 'memberId'],
  computed: {
    ...mapGetters(['user']),
    mId () {
      return this.memberId || (this.$route.name === 'member-profile' ? this.$route.params.id : null)
    }
  },
  components: {
    ProfileHome
  }
}
</script>
